import MyVerticalTimeline from '../../components/MyVerticalTimeline/MyVerticalTimeline';


export default function ResumePage (){
      
    return(
        <>
        <MyVerticalTimeline />
        </>
    )
}